'use client'

import React from 'react'

import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

import MainLayout from '../MainLayout'

const { usePathname } = getLivSharedPathnamesNavigation()

type LayoutProviderProps = {
  children: React.ReactNode
}

export default function LayoutProvider({
  children,
}: LayoutProviderProps): React.JSX.Element {
  const pathname = usePathname() || ''
  const staticPagesWithoutNavbar = [
    '/user/thankyou/',
    '/user/sign-in/',
    '/user/apply/',
    '/user/sign-up/',
  ]
  const regexPagesWithoutNavbar = [/^\/legal-policies\/.*/]

  const isStaticPageWithoutNavbar = staticPagesWithoutNavbar.some(
    (page) => pathname === page,
  )
  const isRegexPageWithoutNavbar = regexPagesWithoutNavbar.some((regex) =>
    regex.test(pathname),
  )

  if (isStaticPageWithoutNavbar || isRegexPageWithoutNavbar) {
    return <>{children}</>
  }
  return <MainLayout>{children}</MainLayout>
}
