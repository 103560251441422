'use client'

import Script from 'next/script'

function WebVitals() {
  return (
    <div>
      <Script
        id="web-vitals-ga4"
        src="/scripts/web-vitals-ga4.js"
        strategy="afterInteractive"
      />
      <Script
        id="web-vitals-cdn"
        src="/scripts/wev-vitals-cdn.js"
        strategy="afterInteractive"
      />
    </div>
  )
}

export default WebVitals
