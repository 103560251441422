'use client'

import React from 'react'

import LayoutProvider from '@layout/LayoutProvider'

export default function ClientLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return <LayoutProvider>{children}</LayoutProvider>
}
