import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1483400865/src/liv-place-frontend/apps/web/src/app/[locale]/clientLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1483400865/src/liv-place-frontend/apps/web/src/app/[locale]/layout/LivAnalyticsProvider/LivAnalyticsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1483400865/src/liv-place-frontend/apps/web/src/contextProviders/LivUserContextProvider/LivUserProviderContextProviderClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1483400865/src/liv-place-frontend/apps/web/src/contextProviders/MuiThemeContextProvider/MuiThemeContextProviderClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1483400865/src/liv-place-frontend/apps/web/src/UI/sharedComponents/LivQueryProvider/LivQueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/codebuild/output/src1483400865/src/liv-place-frontend/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","MultisessionAppSupport","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/codebuild/output/src1483400865/src/liv-place-frontend/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["EmailLinkErrorCode","MagicLinkErrorCode","WithClerk","WithSession","WithUser","isClerkAPIResponseError","isEmailLinkError","isKnownError","isMagicLinkError","isMetamaskError","useAuth","useClerk","useEmailLink","useMagicLink","useOrganization","useOrganizationList","useOrganizations","useSession","useSessionList","useSignIn","useSignUp","useUser","withClerk","withSession","withUser"] */ "/codebuild/output/src1483400865/src/liv-place-frontend/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/codebuild/output/src1483400865/src/liv-place-frontend/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1483400865/src/liv-place-frontend/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1483400865/src/liv-place-frontend/node_modules/photoswipe/dist/photoswipe.css");
